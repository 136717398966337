/* eslint-disable */
import {
  Button,
  Card,
  Col,
  notification,
  Row,
  Form,
  Input,
  Select,
} from 'antd';
import { useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import Papa from 'papaparse';
import { BigNumber } from 'bignumber.js';
import { ethers } from 'ethers';

import { ENV_ROOT } from 'env';
import { request } from '../../../utils/request';
import './styles/import-not-minted-nft.scss';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const { Option } = Select;

export function ImportNotMintNft() {
  const [form] = Form.useForm();
  const [isSubmiting, setIsSubmiting] = useState<boolean>(false);
  const [file, setFile] = useState<any>(null);
  const [addressCollection, setAddressCollection] = useState('');
  const [networkType, setNetworkType] = useState('');

  const genTokenIdForMainStore = (address, supply) => {
    address = address.toLowerCase();

    const id = new Date().getTime();
    const hex =
      address +
      id.toString(16).padStart(14, '0') +
      supply.toString(16).padStart(10, '0');
    const number = new BigNumber(hex.toLowerCase());
    return String(number.toFixed());
  };

  const onFinish = async () => {
    const URL = `${ENV_ROOT.REACT_APP_PUBLIC_SERVER_DOMAIN}/api/v1/nft/import`;
    if (!file) {
      notification.error({
        message: 'Error',
        description: 'You need to import files!!!',
        duration: 4,
      });
      return;
    }
    if (file) {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: async function (results) {
          const data = [...results.data]
            .map((nft, index) => {
              if (nft.token_id === 'NULL') {
                nft.count = 1;
              } else {
                nft.count += 1;
              }
              if (
                !ethers.isAddress(nft.creator_address) ||
                !nft.file_name
              ) {
                console.error('error nft', nft);
                return null;
              }

              nft['tokenId'] = genTokenIdForMainStore(
                ethers.getAddress(nft.creator_address),
                index + 1,
              );
              nft[
                'nftPreviewUrl'
              ] = `https://nft-smapoke-market2.s3.ap-southeast-1.amazonaws.com/nft-files/${nft.file_name}`;
              nft['creatorAddress'] = ethers.getAddress(
                nft.creator_address,
              );
              nft['title'] = nft.name || '';
              nft['type'] = nft.interface === 'erc1155' ? 'ERC1155' : 'ERC721';
              nft['maxQuantity'] = !!Number(nft.token_amount)
                ? Number(nft.token_amount)
                : 1;
              nft['networkType'] =
                nft.net === 'ETH'
                  ? 'ethereum'
                  : nft.net === 'MATIC'
                  ? 'polygon'
                  : 'bsc';
              nft['category'] = isNaN(Number(nft.category))
                ? 0
                : Number(nft.category);
              return nft;
            })
            .filter(e => !!e);

          try {
            setIsSubmiting(true);
            const res: any = await request(URL, null, 'POST', {
              nfts: data,
              collectionAddress: addressCollection,
              networkType,
            });

            if (res.code === 400) {
              notification.error({
                message: 'Error',
                description: 'Import Error',
                duration: 4,
              });
            } else {
              notification.success({
                message: 'Success',
                description: 'Import NFTs successfully',
                duration: 4,
              });
            }
          } catch (error) {
            console.error(error);
          } finally {
            setIsSubmiting(false);
            setFile(null);
          }
        },
      });
    }
  };

  const handleChange = (info: any) => {
    setFile(info.target.files[0]);
  };

  return (
    <>
      <Card
        bordered={false}
        className="criclebox tablespace mb-24 container-ip-collection"
        title="Import NFTs"
      >
        <div
          className="d-flex flex-column"
          style={{ margin: '6rem auto 20rem' }}
        >
          <Row className="w-full" justify="center" align="top">
            <Col span={24} xl={12}>
              <Form
                {...layout}
                form={form}
                name="control-hooks"
                onFinish={onFinish}
              >
                <Form.Item
                  name="file"
                  label="Choose file"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <input
                    type={'file'}
                    id="csvFileInput"
                    accept={'.csv'}
                    onChange={handleChange}
                  />
                </Form.Item>
                <Form.Item
                  name="address"
                  label="Collection Address"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter address"
                    onChange={e => setAddressCollection(e.target.value)}
                  />
                </Form.Item>
                <Form.Item label="Network type" name="networkType">
                  <Select
                    placeholder="Search by Network type"
                    onChange={value => setNetworkType(value)}
                  >
                    <Option value="ethereum">ETH</Option>
                    <Option value="bsc">BSC</Option>
                    <Option value="polygon">POLYGON</Option>
                  </Select>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="btn-submit-ip"
                    disabled={isSubmiting}
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </div>
      </Card>
    </>
  );
}
