import { ENV_ROOT } from './../../../../env/index';
/* eslint-disable no-fallthrough */
import { LOCALSTORAGE_USER } from '../../../../constants';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { ErrorType } from 'types/Error';
import { getUserSession } from 'utils/@session';
import { request } from 'utils/request';
import { userAction as actions } from '.';
import { selectAddress } from './selectors';
import { User } from './types';

export function* getUser(value) {
  const { payload } = value;
  // Select address from store
  const address: string = yield select(selectAddress);
  if (!address) {
    yield put(actions.loginError(ErrorType.EMPTY));
    return;
  }
  const requestURL = `${ENV_ROOT.REACT_APP_PUBLIC_SERVER_DOMAIN}/api/v1/account/login`;
  try {
    // Call our request helper (see 'utils/request')
    let response: User = yield call(request, requestURL, null, 'POST', payload);
    if (response) {
      const { code } = response;
      switch (code) {
        case 400:
          yield put(actions.loginError(ErrorType.EMPTY));
          break;
        case 404:
          yield put(actions.loginError(ErrorType.NOT_FOUND));
          break;
        case 200:
        case undefined:
          localStorage.setItem(
            LOCALSTORAGE_USER,
            JSON.stringify({ ...response, walletAddress: address }),
          );
          yield put(actions.logged({ ...response, address }));
          break;
        default:
          break;
      }
    } else {
      yield put(actions.loginError(ErrorType.HAS_NO_REPO));
    }
  } catch (err: any) {
    console.error(err);
  }
}

export function* changeUser() {
  // Select address from store
  const address: string = yield select(selectAddress);
  if (!address) {
    localStorage.removeItem(LOCALSTORAGE_USER);
    yield put(actions.logout());
    return;
  } else {
    const user = getUserSession();
    if (address !== user.walletAddress && user.walletAddress) {
      localStorage.removeItem(LOCALSTORAGE_USER);
      yield put(actions.logout());
    }
    return;
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* userFormSaga() {
  // Watches for loadRepos actions and calls getRepos when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(actions.login.type, getUser);
  yield takeLatest(actions.changeAddress.type, changeUser);
}
