import React, { memo } from 'react';
import { Button } from 'antd';

interface Props {
  handleCancel: () => void;
  handleOk: () => void;
  disabled?: boolean;
  titleBtnOk: string;
  style?: object;
}

export const CustomFooterModal = memo(
  ({ handleCancel, handleOk, disabled, titleBtnOk, style }: Props) => {
    return (
      <div style={style}>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button
          disabled={disabled}
          className="ant-btn ant-btn-primary"
          onClick={handleOk}
        >
          {titleBtnOk}
        </Button>
      </div>
    );
  },
);
