import { Card, Col, Pagination, Row, Table } from 'antd';
import { ENV_ROOT } from 'env';

import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { request } from 'utils/request';
import styles from './styles/contact.module.scss';

export function Contact() {
  const location: any = useLocation();
  const [currentPage, setCurrentPage] = useState(1);
  const [dataTable, setDataTable] = useState<any>();
  const [totalItem, setTotalItem] = useState(1);
  const LIMIT = 10;

  const columns = [
    {
      title: 'Id',
      key: 'id',
      dataIndex: 'id',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Subject',
      dataIndex: 'subject',
      key: 'subject',
    },
    {
      title: 'Message',
      dataIndex: 'message',
      key: 'message',
    },
  ];
  const [loading, setLoading] = useState(true);

  const getContactData = useCallback(
    async params => {
      setLoading(true);
      const contactURL = `${ENV_ROOT.REACT_APP_PUBLIC_SERVER_DOMAIN}/api/v1/contact`;
      try {
        const paramConvert = {
          ...params,
          limit: LIMIT,
          page: currentPage.toString(),
        };
        const data: any = await request(contactURL, paramConvert, 'GET');
        const makeDataTable = data.items.map(item => {
          return {
            ...item,
            action: {
              email: item.email,
            },
          };
        });
        setTotalItem(data.meta.totalItem);
        setDataTable(makeDataTable);
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    },
    [currentPage],
  );

  const handleChangePage = page => {
    setCurrentPage(page);
  };

  useEffect(() => {
    getContactData('');
  }, [getContactData, location, currentPage]);

  return (
    <>
      <div className="header-action"></div>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Contact"
            >
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={dataTable}
                  pagination={false}
                  className="ant-border-space"
                  loading={loading}
                />
                <Pagination
                  className={styles['contact-pagination']}
                  showSizeChanger={false}
                  current={currentPage}
                  onChange={handleChangePage}
                  showLessItems
                  total={totalItem}
                  defaultPageSize={LIMIT}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}
