import { request } from '@utils/request';
import { Button, Form, Input, notification, Select } from 'antd';
import { ENV_ROOT } from 'env';
import React, { useEffect, useState } from 'react';
import styles from './styles/styles.module.scss';

interface IOption {
  value: number;
  label: string;
}

interface IData {
  id?: number | string;
  trendingDuration: number;
  serviceFee: number;
  restrictUsername: string;
  restrictCollection: string;
  addressServiceFee: string;
  createdAt?: any;
  updatedAt?: any;
}

const options: Array<IOption> = [
  { value: 7, label: '7 days' },
  { value: 30, label: '30 days' },
  { value: 365, label: '365 days' },
];

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: '${label} is required!',
};
/* eslint-enable no-template-curly-in-string */

const SystemSettings = () => {
  const [dataSettings, setDataSettings] = useState<IData | null>(null);
  const [form] = Form.useForm();
  useEffect(() => {
    getDataSettings();
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      trendingDuration: dataSettings?.trendingDuration,
      serviceFee: dataSettings?.serviceFee,
      restrictUsername: dataSettings?.restrictUsername,
      restrictCollection: dataSettings?.restrictCollection,
      addressServiceFee: dataSettings?.addressServiceFee,
    });
  }, [dataSettings, form]);

  const getDataSettings = async () => {
    const URL = `${ENV_ROOT.REACT_APP_PUBLIC_SERVER_DOMAIN}/api/v1/system-settings`;
    try {
      const data: any = await request(URL, null, 'GET');
      setDataSettings(data);
    } catch (error) {
      console.error(error);
    }
  };

  const onFinish = async (values: IData) => {
    const data: IData = { ...values, serviceFee: Number(values.serviceFee) };
    const URL = `${ENV_ROOT.REACT_APP_PUBLIC_SERVER_DOMAIN}/api/v1/system-settings`;
    try {
      await request(URL, '', 'POST', data);
      notification.success({
        message: 'Success',
        description: 'Submit successfully',
        duration: 5,
      });
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Something wrong',
        duration: 5,
      });
    }
  };

  return (
    <div className={styles.container}>
      <Form
        {...layout}
        form={form}
        name="nest-messages"
        onFinish={onFinish}
        initialValues={{
          trendingDuration: dataSettings?.trendingDuration,
          serviceFee: dataSettings?.serviceFee,
          restrictUsername: dataSettings?.restrictUsername,
          restrictCollection: dataSettings?.restrictCollection,
          addressServiceFee: dataSettings?.addressServiceFee,
        }}
        validateMessages={validateMessages}
      >
        <Form.Item
          name="trendingDuration"
          label="Update Trending Durations"
          rules={[{ required: true }]}
        >
          <Select options={options} />
        </Form.Item>
        <Form.Item
          name="serviceFee"
          label="Service Fee"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="restrictUsername" label="Restrict username">
          <Input.TextArea />
        </Form.Item>
        <Form.Item name="restrictCollection" label="Restrict Collection Urls">
          <Input.TextArea />
        </Form.Item>
        <Form.Item
          name="addressServiceFee"
          label="Address Service Fee"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default SystemSettings;
