import { Form, Input, Modal, notification } from 'antd';
import { ENV_ROOT } from 'env';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { request } from 'utils/request';
import { CustomFooterModal } from '../../../../components/CustomFooterModal';

export const CreateAdminModal = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [walletAddress, setWalletAddress] = useState('');
  const [adminName, setAdminName] = useState('');
  const [formValid, setFormValid] = useState(false);
  const [existedAddress, setExistedAddress] = useState(false);
  useEffect(() => {
    if (
      adminName.trim() &&
      walletAddress.trim() &&
      walletAddress.match('^0x[a-fA-F0-9]{40}$')
    ) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [adminName, walletAddress]);
  const handleCancel = () => {
    navigate(-1);
  };

  const onFinish = async () => {
    const createCategoryURL = `${ENV_ROOT.REACT_APP_PUBLIC_SERVER_DOMAIN}/api/v1/admin`;
    const dataCreate = {
      walletAddress: walletAddress,
      name: adminName,
    };
    try {
      const requestCreate: any = await request(
        createCategoryURL,
        '',
        'POST',
        dataCreate,
      );
      if (requestCreate) {
        if (requestCreate?.message && requestCreate?.code) {
          if (
            requestCreate?.message === 'Admin already existed' &&
            requestCreate?.code === 400
          ) {
            setExistedAddress(true);
            form.setFields([
              {
                name: 'adminWalletAddress',
                errors: ['Wallet address existed'],
              },
            ]);
          } else {
            notification.error({
              message: 'Error',
              description: requestCreate.message,
              duration: 5,
            });
          }
        }
        if (Number.isInteger(requestCreate)) {
          notification.success({
            message: 'Success',
            description: 'Admin created successfully',
            duration: 5,
          });
          navigate(-1);
        }
      }
    } catch (error: any) {
      notification.error({
        message: 'Error',
        description: 'Something wrong',
        duration: 5,
      });
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.error('Failed:', errorInfo);
  };

  return (
    <>
      <Modal
        title="Create An Admin"
        visible={true}
        onOk={() => form.submit()}
        onCancel={handleCancel}
        footer={
          <CustomFooterModal
            handleCancel={handleCancel}
            handleOk={() => form.submit()}
            style={footerModalStyle}
            titleBtnOk="Create"
            disabled={!formValid}
          />
        }
      >
        <Form
          name="basic"
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          form={form}
          className="form-create-admin"
        >
          <Form.Item
            name="adminName"
            rules={[
              { required: true, message: 'Please input admin name!' },
              {
                whitespace: true,
                message: 'Please input wallet address!',
              },
            ]}
            label={<p className="m-0">Admin Name</p>}
          >
            <Input.TextArea
              value={adminName}
              onChange={(e: any) => setAdminName(e.target.value)}
              showCount
              maxLength={30}
              autoSize={{ maxRows: 1 }}
              placeholder="Input Admin Name"
            />
          </Form.Item>

          <Form.Item
            label="Admin Wallet Address"
            name="adminWalletAddress"
            validateStatus={existedAddress ? 'error' : ''}
            rules={[
              { required: true, message: 'Please input wallet address!' },
              {
                whitespace: true,
                message: 'Please input wallet address!',
              },
              {
                pattern: new RegExp('^0x[a-fA-F0-9]{40}$'),
                message: 'Invalid Address!',
              },
            ]}
          >
            <Input.TextArea
              placeholder="Input Admin Wallet Address"
              value={walletAddress}
              onChange={(e: any) => {
                setWalletAddress(e.target.value);
                setExistedAddress(false);
              }}
              autoSize={{ minRows: 3 }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

const footerModalStyle = {
  display: 'flex',
  justifyContent: 'flex-end',
};
