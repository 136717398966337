import Main from 'layout/Main';
import { Navigate, useLocation } from 'react-router-dom';
import { getUserSession } from 'utils/@session';

const RouteLayout = () => {
  const user: any = getUserSession();
  const location = useLocation()
  if(!user?.accessToken){
    return <Navigate to="/login" state={{from: location}} />;
  }
  return <Main />
};

export default RouteLayout;
