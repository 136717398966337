export interface Error {
  status: number;
  messenger: string;
}

export enum ErrorType {
  RESPONSE_ERROR = 1,
  NOT_FOUND = 2,
  EMPTY = 3,
  HAS_NO_REPO = 4,
}
