/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// import { useState } from "react";
import { Menu } from 'antd';
import { useUserFormSlice } from 'app/pages/SignInPage/slice';
import { useDispatch } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import logo from 'assets/images/smapocke.png';
import { TeamOutlined } from '@ant-design/icons';
import { LOCALSTORAGE_USER } from '../constants';

function Sidenav({ color }) {
  const dispatch = useDispatch();
  const { actions } = useUserFormSlice();
  const { pathname } = useLocation();
  const page = pathname.replace('/', '');

  const onSignOut = () => {
    localStorage.removeItem(LOCALSTORAGE_USER);
    dispatch(actions.logout());
  };

  const icon1 = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      key={1}
    >
      <path
        d="M3 4C3 3.44772 3.44772 3 4 3H16C16.5523 3 17 3.44772 17 4V6C17 6.55228 16.5523 7 16 7H4C3.44772 7 3 6.55228 3 6V4Z"
        fill={color}
      ></path>
      <path
        d="M3 10C3 9.44771 3.44772 9 4 9H10C10.5523 9 11 9.44771 11 10V16C11 16.5523 10.5523 17 10 17H4C3.44772 17 3 16.5523 3 16V10Z"
        fill={color}
      ></path>
      <path
        d="M14 9C13.4477 9 13 9.44771 13 10V16C13 16.5523 13.4477 17 14 17H16C16.5523 17 17 16.5523 17 16V10C17 9.44771 16.5523 9 16 9H14Z"
        fill={color}
      ></path>
    </svg>,
  ];

  const icon2 = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={2}
    >
      <path
        d="M9 2C8.44772 2 8 2.44772 8 3C8 3.55228 8.44772 4 9 4H11C11.5523 4 12 3.55228 12 3C12 2.44772 11.5523 2 11 2H9Z"
        fill={color}
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 5C4 3.89543 4.89543 3 6 3C6 4.65685 7.34315 6 9 6H11C12.6569 6 14 4.65685 14 3C15.1046 3 16 3.89543 16 5V16C16 17.1046 15.1046 18 14 18H6C4.89543 18 4 17.1046 4 16V5ZM7 9C6.44772 9 6 9.44772 6 10C6 10.5523 6.44772 11 7 11H7.01C7.56228 11 8.01 10.5523 8.01 10C8.01 9.44772 7.56228 9 7.01 9H7ZM10 9C9.44772 9 9 9.44772 9 10C9 10.5523 9.44772 11 10 11H13C13.5523 11 14 10.5523 14 10C14 9.44772 13.5523 9 13 9H10ZM7 13C6.44772 13 6 13.4477 6 14C6 14.5523 6.44772 15 7 15H7.01C7.56228 15 8.01 14.5523 8.01 14C8.01 13.4477 7.56228 13 7.01 13H7ZM10 13C9.44772 13 9 13.4477 9 14C9 14.5523 9.44772 15 10 15H13C13.5523 15 14 14.5523 14 14C14 13.4477 13.5523 13 13 13H10Z"
        fill={color}
      ></path>
    </svg>,
  ];

  const icon3 = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={3}
    >
      <path
        d="M4 4C2.89543 4 2 4.89543 2 6V7H18V6C18 4.89543 17.1046 4 16 4H4Z"
        fill={color}
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 9H2V14C2 15.1046 2.89543 16 4 16H16C17.1046 16 18 15.1046 18 14V9ZM4 13C4 12.4477 4.44772 12 5 12H6C6.55228 12 7 12.4477 7 13C7 13.5523 6.55228 14 6 14H5C4.44772 14 4 13.5523 4 13ZM9 12C8.44772 12 8 12.4477 8 13C8 13.5523 8.44772 14 9 14H10C10.5523 14 11 13.5523 11 13C11 12.4477 10.5523 12 10 12H9Z"
        fill={color}
      ></path>
    </svg>,
  ];

  const icon4 = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={4}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 6C3 4.34315 4.34315 3 6 3H16C16.3788 3 16.725 3.214 16.8944 3.55279C17.0638 3.89157 17.0273 4.29698 16.8 4.6L14.25 8L16.8 11.4C17.0273 11.703 17.0638 12.1084 16.8944 12.4472C16.725 12.786 16.3788 13 16 13H6C5.44772 13 5 13.4477 5 14V17C5 17.5523 4.55228 18 4 18C3.44772 18 3 17.5523 3 17V6Z"
        fill={color}
      ></path>
    </svg>,
  ];

  const icon5 = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={5}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM12 7C12 8.10457 11.1046 9 10 9C8.89543 9 8 8.10457 8 7C8 5.89543 8.89543 5 10 5C11.1046 5 12 5.89543 12 7ZM9.99993 11C7.98239 11 6.24394 12.195 5.45374 13.9157C6.55403 15.192 8.18265 16 9.99998 16C11.8173 16 13.4459 15.1921 14.5462 13.9158C13.756 12.195 12.0175 11 9.99993 11Z"
        fill={color}
      ></path>
    </svg>,
  ];

  // const icon6 = [
  //   <svg
  //     width="20"
  //     height="20"
  //     viewBox="0 0 20 20"
  //     fill="none"
  //     xmlns="http://www.w3.org/2000/svg"
  //     key={6}
  //   >
  //     <path
  //       fillRule="evenodd"
  //       clipRule="evenodd"
  //       d="M6 2C5.44772 2 5 2.44772 5 3V4H4C2.89543 4 2 4.89543 2 6V16C2 17.1046 2.89543 18 4 18H16C17.1046 18 18 17.1046 18 16V6C18 4.89543 17.1046 4 16 4H15V3C15 2.44772 14.5523 2 14 2C13.4477 2 13 2.44772 13 3V4H7V3C7 2.44772 6.55228 2 6 2ZM6 7C5.44772 7 5 7.44772 5 8C5 8.55228 5.44772 9 6 9H14C14.5523 9 15 8.55228 15 8C15 7.44772 14.5523 7 14 7H6Z"
  //       fill={color}
  //     ></path>
  //   </svg>,
  // ];

  // const icon8 = [
  //   <svg
  //     width="20"
  //     height="20"
  //     viewBox="0 0 20 20"
  //     fill="none"
  //     xmlns="http://www.w3.org/2000/svg"
  //     key={8}
  //   >
  //     <path
  //       fillRule="evenodd"
  //       clipRule="evenodd"
  //       d="M6 2C5.44772 2 5 2.44772 5 3V4H4C2.89543 4 2 4.89543 2 6V16C2 17.1046 2.89543 18 4 18H16C17.1046 18 18 17.1046 18 16V6C18 4.89543 17.1046 4 16 4H15V3C15 2.44772 14.5523 2 14 2C13.4477 2 13 2.44772 13 3V4H7V3C7 2.44772 6.55228 2 6 2ZM6 7C5.44772 7 5 7.44772 5 8C5 8.55228 5.44772 9 6 9H14C14.5523 9 15 8.55228 15 8C15 7.44772 14.5523 7 14 7H6Z"
  //       fill={color}
  //     ></path>
  //   </svg>,
  // ];

  const icon7 = [
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      key={7}
      strokeLinecap="round"
      strokeLinejoin="round"
      opacity={0.4}
    >
      <circle cx="12" cy="12" r="3"></circle>
      <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
    </svg>,
  ];

  const icon9 = [
    <svg
      width="86"
      height="94"
      viewBox="0 0 86 94"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={9}
    >
      <rect width="86" height="94" />
      <path
        d="M37.2577 17H18.2969C17.038 17 16 18.1346 16 19.5106V40.2351C16 41.6111 17.038 42.7457 18.2969 42.7457H37.2577C38.5166 42.7457 39.5546 41.6111 39.5546 40.2351V19.5106C39.5546 18.1346 38.5166 17 37.2577 17ZM34.9607 37.8452H20.4834V21.9005H34.9607V37.8452Z"
        fill="black"
      />
      <path
        d="M37.2577 50.2775H18.2969C17.038 50.2775 16 51.4121 16 52.7881V73.5127C16 74.8887 17.038 76.0233 18.2969 76.0233H37.2577C38.5166 76.0233 39.5546 74.8887 39.5546 73.6334V52.7881C39.5546 51.4121 38.5166 50.2775 37.2577 50.2775ZM34.9607 71.1228H20.4834V55.178H34.9607V71.1228Z"
        fill="black"
      />
      <path
        d="M44.9546 32.3171H67.7031C68.962 32.3171 70 31.1825 70 29.8065C70 28.4305 68.962 27.2959 67.7031 27.2959H44.9546C43.6957 27.2959 42.6577 28.4305 42.6577 29.8065C42.6577 31.1945 43.6847 32.3171 44.9546 32.3171Z"
        fill="black"
      />
      <path
        d="M67.7031 60.5734H44.9546C43.6957 60.5734 42.6577 61.708 42.6577 63.084C42.6577 64.46 43.6957 65.5946 44.9546 65.5946H67.7031C68.962 65.5946 70 64.46 70 63.084C70 61.708 68.962 60.5734 67.7031 60.5734Z"
        fill="black"
      />
    </svg>,
  ];

  return (
    <>
      <div className="brand">
        <img src={logo} alt="" />
      </div>
      <hr />
      <Menu theme="light" mode="inline">
        <Menu.Item key="menu_1">
          <NavLink end to="/">
            <span
              className="icon"
              style={{
                background: page === '' ? color : '',
              }}
            >
              {icon1}
            </span>
            <span className="label">Dashboard</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="menu_2">
          <NavLink to="/user-management">
            <span
              className="icon"
              style={{
                background: page === 'user-management' ? color : '',
              }}
            >
              <TeamOutlined />{' '}
            </span>
            <span className="label">Users management</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="menu_3">
          <NavLink to="/admin-management">
            <span
              className="icon"
              style={{
                background: page === 'admin-management' ? color : '',
              }}
            >
              {icon5}
            </span>
            <span className="label">Admin management</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="menu_4">
          <NavLink to="/collection-management">
            <span
              className="icon"
              style={{
                background: page === 'collection-management' ? color : '',
              }}
            >
              {icon3}
            </span>
            <span className="label">Collections management</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="menu_5">
          <NavLink to="/nft-management">
            <span
              className="icon"
              style={{
                background: page === 'nft-management' ? color : '',
              }}
            >
              {icon2}
            </span>
            <span className="label">NFTs management</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="menu_7">
          <NavLink to="/reports-management">
            <span
              className="icon"
              style={{
                background: page === 'reports-management' ? color : '',
              }}
            >
              {icon4}
            </span>
            <span className="label">Reports</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="menu_13">
          <NavLink to="/listing-nft">
            <span
              className="icon"
              style={{
                background: page === 'listing-nft' ? color : '',
              }}
            >
              {icon9}
            </span>
            <span className="label">Listing Nft</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="menu_8">
          <NavLink to="/subscriber-management">
            <span
              className="icon"
              style={{
                background: page === 'subscriber-management' ? color : '',
              }}
            >
              <TeamOutlined />
            </span>
            <span className="label">Subscriber</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="menu_9">
          <NavLink to="/contact">
            <span
              className="icon"
              style={{
                background: page === 'contact' ? color : '',
              }}
            >
              <TeamOutlined />
            </span>
            <span className="label">Contact</span>
          </NavLink>
        </Menu.Item>
        {/* <Menu.Item key="menu_13">
          <NavLink to="/referral-user">
            <span
              className="icon"
              style={{
                background: page === 'referral-user' ? color : '',
              }}
            >
              <TeamOutlined />
            </span>
            <span className="label">User Referral</span>
          </NavLink>
        </Menu.Item> */}
        <Menu.Item key="menu_10">
          <NavLink to="/system-settings">
            <span
              className="icon"
              style={{
                background: page === 'system-settings' ? color : '',
              }}
            >
              {icon7}
            </span>
            <span className="label">System Settings</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item className="menu-item-header" key="Header_1">
          Extend Pages
        </Menu.Item>
        {/* <Menu.Item key="menu_11">
          <NavLink to="/import-collection">
            <span
              className="icon"
              style={{
                background: page === 'update-metadata' ? color : '',
              }}
            >
              {icon6}
            </span>
            <span className="label">Import Collection</span>
          </NavLink>
        </Menu.Item> */}
        {/* <Menu.Item key="import-nfts">
          <NavLink to="/import-nfts">
            <span
              className="icon"
              style={{
                background: page === 'update-metadata' ? color : '',
              }}
            >
              {icon8}
            </span>
            <span className="label">Import Nfts</span>
          </NavLink>
        </Menu.Item> */}
        <Menu.Item key="menu_12">
          <a onClick={onSignOut}>
            <span className="icon">{icon5}</span>
            <span className="label">Sign Out</span>
          </a>
        </Menu.Item>
      </Menu>
    </>
  );
}

export default Sidenav;
