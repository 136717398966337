/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core';
import { useEagerConnect } from 'hooks/useEagerConnect';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { getUserSession } from 'utils/@session';
import { GlobalStyle } from '../styles/global-styles';
import { CollectionManagement } from './pages/CollectionManagement/Loadable';
import { DropsCollection } from './pages/DropsCollection/Loadable';
import { HomePage } from './pages/HomePage/Loadable';
import { NFTManagement } from './pages/NftManagement/Loadable';
import { NotFoundPage } from './pages/NotFoundPage/Loadable';
import { ReportsManagement } from './pages/ReportsManagement/Loadable';
import { SignInPage } from './pages/SignInPage/Loadable';
import { useUserFormSlice } from './pages/SignInPage/slice';
import { selectAccessToken } from './pages/SignInPage/slice/selectors';
import { ModalDetailUser } from './pages/UserManagement/features/ModalDetailUser/Loadable';
import { UserManagement } from './pages/UserManagement/Loadable';
import { AdminManagement } from './pages/AdminManagement/Loadable';
import { ModalDeleteAdmin } from './pages/AdminManagement/features/ModalDeleteAdmin';
import { CreateAdminModal } from './pages/AdminManagement/features/ModalCreateAdmin';
import { LOCALSTORAGE_USER } from '../constants';
import { ModalDeleteDropCollection } from './pages/DropsCollection/features/ModalDeleteDropCollection/Loadable';
import { ModalUploadDropCollection } from './pages/DropsCollection/features/ModalUploadDropCollection/Loadable';
import { injected } from 'connectors';
import SystemSettings from './pages/SystemSettings';
import { SubscriberManagement } from './pages/SubscriberManagement';
import { ImportCollection } from './pages/ImportCollection/Loadable';
import { Contact } from './pages/Contact';
import { ImportNotMintNft } from './pages/ImportNotMintedNft/index';
import { ListingNft } from './pages/ListingNft/Loadable';
import { UserReferral } from './pages/UserReferral';
import RouteLayout from 'hocs/RouteLayout';

function Navigations() {
  const location: any = useLocation();
  const background = location && location.state && location.state.background;
  const navigate = useNavigate();
  const { error } = useWeb3React();
  const dispatch = useDispatch();
  const { actions } = useUserFormSlice();

  useEffect(() => {
    if (error instanceof UnsupportedChainIdError) {
      localStorage.removeItem(LOCALSTORAGE_USER);
      dispatch(actions.logout());
    }
  }, [actions, dispatch, error, navigate]);

  return (
    <div>
      <Routes location={background || location}>
        <Route path={'/'} element={<RouteLayout/>}> {/*RouteLayout contain Main layout and logic auth to redirect to /login if unauthorized*/}
          <Route
            path={'/'}
            element={<HomePage />}
          />
          <Route
            path={'/dashboard'}
            element={<HomePage />}
          />
          <Route
            path={'/user-management'}
            element={<UserManagement />}
          />
          <Route
            path={'/admin-management'}
            element={<AdminManagement />}
          />
          <Route
            path={'/nft-management'}
            element={<NFTManagement />}
          />
          <Route
            path={'/listing-nft'}
            element={<ListingNft />}
          />
          <Route
            path={'/reports-management'}
            element={<ReportsManagement />}
          />
          <Route
            path={'/subscriber-management'}
            element={<SubscriberManagement />}
          />
          <Route
            path={'/contact'}
            element={<Contact />}
          />
          <Route
            path={'/referral-user'}
            element={<UserReferral />}
          />
          <Route
            path={'/system-settings'}
            element={<SystemSettings />}
          />
          <Route
            path={'/collection-management'}
            element={<CollectionManagement />}
          />
          <Route
            path={'/drops-collection'}
            element={<DropsCollection />}
          />
          <Route
            path={'/import-collection'}
            element={<ImportCollection />}
          />
          <Route
            path={'/import-nfts'}
            element={<ImportNotMintNft />}
          />
        </Route>
          <Route
            path={'/login'}
            element={<SignInPage />}
          />
          <Route path='*' element={<NotFoundPage />} />
      </Routes>
      {background && (
        <>
        <Routes>
          <Route
            path="/user-management/detail"
            element={<ModalDetailUser />}
          />
          <Route
            path="/admin-management/delete"
            element={<ModalDeleteAdmin />}
          />
          <Route
            path="/admin-management/create"
            element={<CreateAdminModal />}
          />
          <Route
            path="/drops-collection/delete"
            element={<ModalDeleteDropCollection />}
          />
          <Route
            path="/drops-collection/upload"
            element={<ModalUploadDropCollection />}
          />
        </Routes>
        </>
      )}
    </div>
  );
}
declare var window: any;
export function App() {
  const dispatch = useDispatch();
  useSelector(selectAccessToken);
  useEagerConnect();
  const { actions } = useUserFormSlice();
  const { account, active, activate, error, library } = useWeb3React();
  // handle logic to eagerly connect to the injected ethereum provider,
  // if it exists and has granted access already

  window.ethereum?.on('accountsChanged', function (accounts) {
    if (accounts?.length > 0) {
      activate(injected);
    }
  });

  useEffect(() => {
    const { ethereum } = window as any;
    async function onCheckConnectMetamask() {
      const isUnlocked = await ethereum?._metamask.isUnlocked();
      if (!isUnlocked) localStorage.removeItem(LOCALSTORAGE_USER);
    }
    onCheckConnectMetamask();
    function handleAccountChange(account) {
      if (account.length === 0) {
        dispatch(
          actions.changeAddress({
            address: null,
            active,
            accessToken: null,
          }),
        );
      }
    }
    if (library?.provider) {
      library.provider.on('accountsChanged', handleAccountChange);
    }

    return () => {
      if (library?.provider) {
        library.provider.removeListener('accountsChanged', handleAccountChange);
      }
    };
  }, [actions, active, dispatch, library]);

  useEffect(() => {
    const user = getUserSession();
    if (account && active) {
      dispatch(
        actions.changeAddress({
          address: account || '',
          active,
          accessToken: user.accessToken || '',
        }),
      );
    }
  }, [account, actions, active, dispatch, error]);

  return (
    <BrowserRouter>
      <Navigations />
      <GlobalStyle />
    </BrowserRouter>
  );
}
