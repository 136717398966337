import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

// First select the relevant part from the state
const selectDomain = (state: RootState) => state.user || initialState;

export const selectAddress = createSelector(
  [selectDomain],
  userFormState => userFormState.address,
);

export const selectAccessToken = createSelector(
  [selectDomain],
  userFormState => userFormState.accessToken,
);

export const selectLoading = createSelector(
  [selectDomain],
  userFormState => userFormState.loading,
);

export const selectError = createSelector(
  [selectDomain],
  userFormState => userFormState.error,
);
