export const LOCALSTORAGE_USER = 'user';

export const MAX_IMAGE_SIZE = 100000000;

export const REGEX_URL =
  // eslint-disable-next-line no-useless-escape
  /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

export const NETWORK_NAME = {
  ethereum: 'Ethereum',
  polygon: 'Polygon',
  bsc: 'BSC',
};

export const CURRENCY = {
  ethereum: ['weth', 'usdt'],
  polygon: ['weth', 'matic'],
  bsc: ['wbnb', 'sp', 'busd'],
};

export const FILE_EXTENSION = {
  MP4: '.mp4',
  MPEG: '.mpeg',
};

export const REQUIRED = { required: true, message: 'This field is required.' };
