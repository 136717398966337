/* eslint-disable */
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Pagination,
  Row,
  Select,
  Table,
} from 'antd';
import { ENV_ROOT } from 'env';
import _, { debounce } from 'lodash';

import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { request } from 'utils/request';
import styles from './styles/referral.module.scss';
const { Option } = Select;
const initFilterValue = {
  username: '',
  walletAddress: '',
  isNotZeroReferal: 0,
};
export function UserReferral() {
  const location: any = useLocation();
  const [currentPage, setCurrentPage] = useState(1);
  const [dataTable, setDataTable] = useState<any>();
  const [totalItem, setTotalItem] = useState(1);
  const LIMIT = 10;
  const [filter, setFilter] = useState(initFilterValue);
  const [loading, setLoading] = useState(true);
  const isFilterEmpty = Object.values(filter).every(
    x => x === null || x === '',
  );

  const columns = [
    {
      title: 'Id',
      key: 'id',
      dataIndex: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Wallet Address',
      dataIndex: 'walletAddress',
      key: 'walletAddress',
    },
    {
      title: 'Number of referral',
      dataIndex: 'countReferralCode',
      key: 'countReferralCode',
    },
  ];

  const getData = useCallback(
    async params => {
      setLoading(true);
      const categoryURL = `${ENV_ROOT.REACT_APP_PUBLIC_SERVER_DOMAIN}/api/v1/account/management`;
      try {
        const paramConvert = {
          ...params,
          limit: '10',
          page: currentPage.toString(),
        };
        const data: any = await request(categoryURL, paramConvert, 'GET');
        setTotalItem(data.meta.totalItem);
        setDataTable(data.items);
      } catch (error) {
        setDataTable([]);
        setTotalItem(1);
        console.error(error);
      }
      setLoading(false);
    },
    [currentPage],
  );

  const handleChangePage = page => {
    setCurrentPage(page);
  };

  const HandleFilter = e => {
    const filterClone = { ...filter };
    filterClone[e.target.name] = e.target.value;
    setCurrentPage(1);
    setFilter(filterClone);
  };

  console.log('filter,table-header', filter);
  const fetchData = useCallback(debounce(getData, 800), [currentPage, getData]);

  useEffect(() => {
    if (_.isEqual(filter, initFilterValue)) {
      fetchData('');
    } else {
      fetchData({
        ...filter,
      });
    }
  }, [fetchData, location, currentPage, filter]);

  return (
    <>
      <div className="header-action"></div>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="User referral"
            >
              <div className="table-header">
                <Row gutter={15}>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 8 }}
                    lg={{ span: 4 }}
                  >
                    <Form.Item label="Wallet Address">
                      <Input
                        name="walletAddress"
                        value={filter.walletAddress}
                        onChange={HandleFilter}
                        placeholder="Search by Wallet Address"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 8 }}
                    lg={{ span: 4 }}
                  >
                    <Form.Item label="Name">
                      <Input
                        name="username"
                        value={filter.username}
                        onChange={HandleFilter}
                        placeholder="Search User Name"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 8 }}
                    lg={{ span: 4 }}
                  >
                    <Form.Item label="Number of referral:">
                      <Select
                        value={filter.isNotZeroReferal}
                        placeholder="Search by Verification"
                        onChange={value => {
                          const filterClone = { ...filter };
                          filterClone['isNotZeroReferal'] = value;
                          setFilter(filterClone);
                          setCurrentPage(1);
                        }}
                      >
                        <Option value={0}>All</Option>
                        <Option value={1}>None zero</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  {!isFilterEmpty && (
                    <Col span={4} className={styles['reset-btn']}>
                      <Button
                        type="link"
                        onClick={() => {
                          setFilter(initFilterValue);
                          setCurrentPage(1);
                        }}
                      >
                        Reset
                      </Button>
                    </Col>
                  )}
                </Row>
              </div>
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={dataTable}
                  pagination={false}
                  className="ant-border-space"
                  loading={loading}
                />
                <Pagination
                  className={styles['contact-pagination']}
                  showSizeChanger={false}
                  current={currentPage}
                  onChange={handleChangePage}
                  showLessItems
                  total={totalItem}
                  defaultPageSize={LIMIT}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}
