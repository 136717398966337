import { Button, Card, Col, Row, Table, notification } from 'antd';

import { useCallback, useEffect, useState } from 'react';
import { request } from 'utils/request';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import styles from './styles/subscriber.module.scss';
import { ENV_ROOT } from 'env';

export function SubscriberManagement() {
  const [dataTable, setDataTable] = useState<any>();
  const [copyAllEmail, setCopyAllEmail] = useState<any>();
  const [pagination, setPagination] = useState<any>({
    current: 1,
    pageSize: 10,
  });

  const showCopied = () => {
    notification.success({
      message: 'Success',
      description: 'Copy successfully',
      duration: 4,
    });
  };
  const columns = [
    {
      title: 'Id',
      key: 'id',
      dataIndex: 'id',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: ({ email }) => {
        return (
          <CopyToClipboard text={email} onCopy={showCopied}>
            <div>
              <img className={styles.copyButton} src="/gallery.svg" alt="" />
            </div>
          </CopyToClipboard>
        );
      },
    },
  ];
  const [loading, setLoading] = useState(true);

  const getCategoryData = useCallback(async () => {
    setLoading(true);
    const subscribeURL = `${ENV_ROOT.REACT_APP_PUBLIC_SERVER_DOMAIN}/api/v1/subscribe?limit=${pagination.pageSize}&page=${pagination.current}`;
    try {
      const data: any = await request(subscribeURL, '', 'GET');
      const makeDataTable = data.items.map(item => {
        return {
          ...item,
          action: {
            email: item.email,
          },
        };
      });
      const exportEmailArray = data.items.map(item => {
        return item.email;
      });
      setPagination({ ...pagination, total: data?.meta?.totalItem });
      setCopyAllEmail(exportEmailArray);
      setDataTable(makeDataTable);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }, [JSON.stringify(pagination)]);

  useEffect(() => {
    getCategoryData();
  }, [JSON.stringify(pagination)]);

  const handleTableChange = pagination => {
    setPagination({ ...pagination });
  };

  return (
    <>
      <div className="header-action">
        <CopyToClipboard text={copyAllEmail} onCopy={showCopied}>
          <Button type="primary" className="unset-height">
            Copy All Emails
          </Button>
        </CopyToClipboard>
      </div>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Subscriber Management"
            >
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={dataTable}
                  pagination={pagination}
                  className="ant-border-space"
                  loading={loading}
                  onChange={handleTableChange}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}
