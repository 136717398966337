/**
 * index.tsx
 *
 * This is the entry file for the application, only setup
 * code.
 */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import * as React from 'react';
// import * as ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import FontFaceObserver from 'fontfaceobserver';
import { Web3Provider } from '@ethersproject/providers';
import { Harmony } from '@harmony-js/core';

// Use consistent styling
import 'sanitize.css/sanitize.css';
import '../src/assets/styles/main.less';
import '../src/assets/styles/responsive.less';

import { App } from 'app';

import { HelmetProvider } from 'react-helmet-async';

import { configureAppStore } from 'store/configureStore';

import { ThemeProvider } from 'styles/theme/ThemeProvider';

import reportWebVitals from 'reportWebVitals';

// Initialize languages
import './locales/i18n';
import { Web3ReactProvider } from '@web3-react/core';

// Observe loading of Inter (to remove 'Inter', remove the <link> tag in
// the index.html file and this observer)
const openSansObserver = new FontFaceObserver('Inter', {});

// When Inter is loaded, add a font-family using Inter to the body
openSansObserver.load().then(() => {
  document.body.classList.add('fontLoaded');
});

const store = configureAppStore();
const MOUNT_NODE = document.getElementById('root') as HTMLElement;

function getLibrary(provider: any): Web3Provider | Harmony {
  var library: Web3Provider | Harmony;

  if (provider?.chainType === 'hmy') {
    library = provider.blockchain;
  } else {
    library = new Web3Provider(provider);
    library.pollingInterval = 12000;
  }

  return library;
}

const root = createRoot(MOUNT_NODE);

root.render(
  <Provider store={store}>
    <ThemeProvider>
      <HelmetProvider>
        <Web3ReactProvider getLibrary={getLibrary}>
          <React.StrictMode>
            <App />
          </React.StrictMode>
        </Web3ReactProvider>
      </HelmetProvider>
    </ThemeProvider>
  </Provider>,
);

// Hot reloadable translation json files
if (module.hot) {
  module.hot.accept(['./locales/i18n'], () => {
    // No need to render the App again because i18next works with the hooks
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
