import { PayloadAction } from '@reduxjs/toolkit';
import { ErrorType } from 'types/Error';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { userFormSaga } from './saga';
import { User } from './types';

export const initialState: User = {
  address: '',
  active: false,
  accessToken: '',
  loading: false,
  error: null,
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login(state, action: PayloadAction<any>) {
      state.loading = true;
      state.error = null;
    },
    logged(state, action: PayloadAction<User>) {
      const res = action.payload;
      state.address = res?.address;
      state.accessToken = res?.accessToken;
      state.loading = false;
    },
    logout(state) {
      state.accessToken = '';
      state.active = false;
      state.loading = false;
    },
    loginError(state, action: PayloadAction<ErrorType>) {
      state.error = action.payload;
      state.loading = false;
    },
    changeAddress(state, action: PayloadAction<any>) {
      state.accessToken = action.payload?.accessToken;
      state.address = action.payload?.address;
      state.active = action.payload?.active;
    },
  },
});

export const { actions: userAction, reducer } = slice;

export const useUserFormSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: userFormSaga });
  return { actions: slice.actions };
};
