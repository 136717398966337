import { Modal, notification } from 'antd';
import { ENV_ROOT } from 'env';
import { useNavigate, useLocation } from 'react-router-dom';
import { request } from 'utils/request';

export const ModalDeleteAdmin = () => {
  const navigate = useNavigate();
  const location: any = useLocation();
  const { accountId } = location?.state;

  const handleOk = async () => {
    const createCategoryURL = `${ENV_ROOT.REACT_APP_PUBLIC_SERVER_DOMAIN}/api/v1/admin/${accountId}`;

    try {
      const requestCreate: any = await request(createCategoryURL, '', 'DELETE');
      if (requestCreate?.message && requestCreate?.code) {
        notification.success({
          message: 'Error',
          description: requestCreate.message,
          duration: 5,
        });
      } else {
        notification.success({
          message: 'Success',
          description: 'Admin deleted successfully',
          duration: 5,
        });
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Something wrong',
        duration: 5,
      });
      console.error(error);
    }

    navigate(-1);
  };

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <>
      <Modal
        title="COMFIRMATION"
        visible={true}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>{`Are you sure you want to delete `}</p>
      </Modal>
    </>
  );
};
