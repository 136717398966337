import { LoadingIndicator } from 'app/components/LoadingIndicator';
import { LoadingWrapper } from 'app/components/LoadingWrapper';
import { lazyLoad } from 'utils/loadable';

export const ImportCollection = lazyLoad(
  () => import('./index'),
  module => module.ImportCollection,
  {
    fallback: (
      <LoadingWrapper>
        <LoadingIndicator />
      </LoadingWrapper>
    ),
  },
);
