import { LOCALSTORAGE_USER } from '../constants';
import _ from 'lodash';
import { getUserSession } from './@session';
export class ResponseError extends Error {
  public response: Response;

  constructor(response: Response) {
    super(response.statusText);
    this.response = response;
  }
}
/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response: Response, resType = 'json') {
  if (response.status === 204 || response.status === 205) return null;
  if (resType === 'json') return response.json();
  if (resType === 'text') return response.text();
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
function checkStatus(response: Response) {
  try {
    if (response.status === 401 && response.statusText === 'Unauthorized') {
      localStorage.removeItem(LOCALSTORAGE_USER);
      window.location.reload();
    }
    return response;
  } catch (error) {
    throw error;
  }
}

/**
 *
 * @param params The parram
 */
function convertParamToUrl(params: any): string {
  const _params = { ...params };
  for (const key in params) {
    if (Object.prototype.hasOwnProperty.call(_params, key)) {
      const element = _params[key];
      if (_.isString(element) && element === '') delete _params[key];
      if (_.isObject(element) && _.isEmpty(element)) delete _params[key];
      if (_.isArray(element) && element.length === 0) delete _params[key];
      if (_.isUndefined(element)) delete _params[key];
      if (_.isNaN(element)) _params[key] = 0;
      if (!element) delete _params[key];
    }
  }
  let parramString = '';
  Object.keys(params).forEach((key: any, index: number) => {
    const element = params[key];
    let value = JSON.stringify(element);
    if (!_.isObject(element)) value = value.substring(1, value.length - 1);
    if (index === 0) {
      parramString += '?' + key + '=' + value;
    } else {
      parramString += '&' + key + '=' + value;
    }
  });
  return parramString;
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} params    The param
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export async function request(
  url: string,
  params?: any,
  method?: string,
  body?: any,
  bodyType?: 'json' | 'formData',
  resType?: 'json' | 'text',
): Promise<{} | null | { err: ResponseError }> {
  var headers = new Headers();
  const user = getUserSession();
  if (!bodyType || bodyType === 'json') {
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    // headers.append('ngrok-skip-browser-warning', 'true');
  }
  if (user.accessToken)
    headers.append('Authorization', `Bearer ${user.accessToken}`);
  if (params) url += convertParamToUrl(params);
  const options: RequestInit = {
    headers,
    method,
    body: body
      ? !bodyType || bodyType === 'json'
        ? JSON.stringify(body)
        : body
      : null,
  };
  if (method === 'GET') delete options.body;
  const fetchResponse = await fetch(url, options);
  const response = await checkStatus(fetchResponse);
  return await parseJSON(response, resType);
}
